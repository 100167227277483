<template>
    <v-row justify="center">
      <v-dialog v-model="show" width="600px" scrollable persistent>
        <v-card>
          <!-- <v-card-title>{{ datapolicy.policy_header }}</v-card-title>
          <v-divider></v-divider> -->
          <v-card-text class="pa-6" ><p>จะไม่ทำการจัดเก็บใบกำกับภาษีอิเล็กทรอนิค ที่ท่านได้รับจากร้านค้า ห้างสรรพสินค้า หรือผู้ให้บริการที่มีการอนุญาตให้นำส่ง ETAX มาจัดเก็บในแฟ้มจัดเก็บใบกำกับภาษีอิเล็กทรอนิกส์ของท่าน(INBOX)</p><p>จะไม่ทำการจัดเก็บใบกำกับภาษีอิเล็กทรอนิกส์ที่ท่านนำส่ง ให้แก่ผู้ซื้อสินค้า/บริการ ที่มีการอนุญาตให้นำส่ง ETAX มาจัดเก็บในแฟ้มจัดเก็บใบกำกับภาษีอิเล็กทรอนิกส์  (Outbox)</p></v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center">
            <v-btn color="red" outlined @click="$emit('close')">{{ $t("admin.close") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  <script>
  import { mapState, mapGetters } from "vuex";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import gbfGenerate from "@/globalFunctions/generateAuthorize";
  
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });
  
  export default {
    props: ["show"],
    data: function () {
      return {
        consent_reject:""
      };
    },
    computed: {
      ...mapState([
        "username",
        "authorize",
        "account_active",
        "color",
        "role_level",
      ]),
      ...mapState({ processloader: "loading" }),
      ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
      ]),
      shower: {
      get() {
        if (this.show === true) {
          this.fn_getconsent();
        }
        return this.show;
      },
      set(value) {
        if (!value) {
        }
      }
    }
    },
    methods: {
    //ดึงข้อมูลจาก api
    async fn_getconsent() {
      this.consentdata = [];
      let tax =  this.dataAccountActive.taxid || ""
      var payload = {
        account_id: this.dataAccountId,
        taxid:tax
      };
      let auth = await gbfGenerate.generateToken();
      console.log("res",this.dataAccountActive);
     await this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/check_all_consent", payload,{headers: { Authorization: auth.code },}).then((response) => {
        console.log("response", response);
        if (response.data.status === "OK") {
            this.consent_reject = response.data.result[0]["consent_reject"];
        //   for (let i = 0; i < response.data.result.length; i++) {
        //     let data = {};
        //     data["consent_cre_dtm"] = formatDatetime.formatdatetime(response.data.result[i]["consent_date"]);
        //     data["consent_detail"] = response.data.result[i]["consent_detail"];
        //     data["consent_name"] = response.data.result[i]["consent_name"];
        //     data["consent_status_text"] = response.data.result[i]["consent_status"];
        //    data["consent_status"] = response.data.result[i]["consent_status"]
        //    data["consent_id"] = response.data.result[i]["consent_id"];
        //     this.consentdata.push(data);
        //   }
     
        }else{
          Toast.fire({
              icon: "error",
              title: "error",
            });    
        }
      }).catch((error) => {
           console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    },
  };
  </script>
  <style></style>
  